import styled from "styled-components";
import bg from '../../Assets/Footer/footer_bg.png'

export const FooterContainer = styled.div`
  background: url(${bg}) center / cover no-repeat;
  padding: 89px 0 49px;
`
export const FooterWrapper = styled.div`
  max-width: 73.262vw;
  margin: 0 auto;
  padding: 0 20px;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    max-width: 95%;
    padding-left: 15px;
    padding-right: 15px;
  }
`
export const FooterWrapperInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    display: block;
  }
`
export const FooterLeft = styled.div`
  max-width: 18.6vw;
  width: 100%;
  @media screen and (max-width: 767px) {
    max-width: unset;
    margin-bottom: 20px;
  }
`
export const FooterLogo = styled.a`
  display: inline-block;
  margin-bottom: 15px;
  width: 17.431vw;
  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 251px;
  }
`
export const FooterText = styled.p`
  color: var(--Light-Gray-Base, #DCE3EB);
  font-size: 0.97223vw;
  font-weight: 300;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`
export const FooterRight = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 3.5vw;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    gap: unset;
  }
`
export const FooterNavigation = styled.div`
  h3 {
    color: var(--Light-Gray-Base, #DCE3EB);
    font-size: 1.25vw;
    font-weight: 400;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
    margin-bottom: 15px;
    h3 {
      font-size: 18px;
    }
  }
`
export const ListNavigation = styled.ul`
  a {
    text-decoration: none;
    color: var(--Gray-60, #717685);
    font-size: 1.11111vw;
    font-weight: 400;
  }
  @media screen and (max-width: 767px) {
    a {
      font-size: 16px;
    }
  }
`
export const ListSocial = styled.ul`
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    display: inline-block;
    height: 1.25vw;
    width: auto;
  }
  img {
    height: 100%;
    width: auto;
  }
  @media screen and (max-width: 767px) {
    a {
      height: 18px;
    }
  }
`